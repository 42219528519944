<template>
	<div class="page" :style="height" v-loading="downloadLoading">
		<div class="searchbar">
			<div class="action-wrapper pr10">
				<el-button type="primary" size="small" class="table-toolbar-btn" @click="add"
					v-if="perms.includes('library:add')"> 新增 </el-button>
			</div>

			<el-form ref="form" :model="query" :inline="true" label-width="74px" size="small" :show-message="false"
				class="search-form">
				<el-form-item label="关键字">
					<el-input v-model="query.searchKey" clearable placeholder="请输入关键字"></el-input>
				</el-form-item>
				<el-form-item label="机种分类">
					<el-select v-model="query.modelType" filterable clearable placeholder="未选择">
						<el-option v-for="option in modelTypeOptions" :key="option.id" :label="option.name"
							:value="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机型分类">
					<el-select v-model="query.deviceType" filterable clearable placeholder="未选择">
						<el-option v-for="option in deviceTypeOptions" :key="option.id" :label="option.name"
							:value="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="机型名称">
					<el-select v-model="query.deviceName" filterable clearable placeholder="全部">
						<el-option v-for="option in deviceNameOptions" :key="option.id" :label="option.name"
							:value="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="资料类型">
					<el-select v-model="query.materialType" filterable clearable placeholder="全部">
						<el-option v-for="option in resourceTypeOptions" :key="option.id" :label="option.name"
							:value="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
				<el-button class="search-btn" @click="getList(1)"></el-button>
			</el-form>
		</div>

		<div class="main-content">
			<el-table :row-class-name="tableRowClassName" :header-cell-style="{color:'#444'}" :data="data" border
				v-loading="loading" :cell-style="getCellStyle">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="modelType" label="机种分类" width="200" align="left" class-name="fwb"
					header-align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						<span class="content-title"> {{ scope.row.modelType }} </span>
					</template>
				</el-table-column>
				<el-table-column prop="deviceType" label="机型分类" show-overflow-tooltip align="left" header-align="center"></el-table-column>
				<el-table-column prop="deviceName" label="机型名称" show-overflow-tooltip align="left" header-align="center"></el-table-column>
				<el-table-column prop="materialTypeStr" label="资料类型" show-overflow-tooltip align="left" header-align="center">
				</el-table-column>
				<el-table-column prop="materialName" label="资料名称" show-overflow-tooltip align="left" header-align="center"></el-table-column>
				<el-table-column label="操作" width="91" align="center" header-align="center">
					<template slot-scope="scope">
						<div class="table-btn-wrapper">
							<el-button type="text" plain class="table-btn info-text" @click="download(scope.row)"> 下载
							</el-button>
							<el-button type="text" plain class="table-btn info-text" @click="goToDetail(scope.row)"> 详细
							</el-button>
							<el-button type="text" plain class="table-btn info-text" @click="edit(scope.row)"
								v-if="perms.includes('library:update')"> 修改
							</el-button>
							<el-button type="text" plain class="table-btn error-text" @click="del(scope.row)"
								v-if="perms.includes('library:delete')"> 删除
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagination-wrapper pa25">
				<Pagination :page.sync="query.pageNo" :pageSize.sync="query.pageSize" :total="total"
					@sizeChange="handleSizeChange" @currentChange="handleCurrentChange"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination'

	export default {
		components: {
			Pagination
		},
		data() {
			return {
				// 权限
				perms: JSON.parse(window.sessionStorage.getItem('perms')),
				// 查询参数
				query: {
					searchKey: '',
					modelType: '',
					deviceType: '',
					deviceName: '',
					materialType: '',
					pageNo: 1,
					pageSize: 20,
                    libraryDeviceWithout: "library"
				},
				total: 0,
				loading: false,
				// 机种分类选项
				modelTypeOptions: [],
				// 机型分类选项
				deviceTypeOptions: [],
				// 机型名称选项
				deviceNameOptions: [],
				// 资料类型
				resourceTypeOptions: [],
				// 表格数据
				data: [],
				downloadLoading: false,
				height: ''
			}
		},
		watch: {
			modelType(val) {
				this.query.deviceType = ''
				this.query.deviceName = ''

				let options = []
				for (const model of this.modelTypeOptions) {
					if (model.name === val) {
						options = model.children || []
						break
					}
				}
				this.deviceTypeOptions = options
			},
			deviceType(val) {
				this.query.deviceName = ''

				let options = []
				for (const device of this.deviceTypeOptions) {
					if (device.name === val) {
						options = device.children || []
						break
					}
				}
				this.deviceNameOptions = options
			}
		},
		computed: {
			modelType() {
				return this.query.modelType
			},
			deviceType() {
				return this.query.deviceType
			}
		},
		created() {
			this.getModelDevice()
			this.getResourceType()

			this.query.pageNo = this.$route.params.pageNo || 1
			this.query.searchKey = this.$route.params.searchKey || ''
			this.query.modelType = this.$route.params.modelType || ''
			this.query.deviceType = this.$route.params.deviceType || ''
			this.query.deviceName = this.$route.params.deviceName || ''
			this.$nextTick(() => {
				this.query.deviceType = this.$route.params.deviceType || ''

				this.$nextTick(() => {
					this.query.deviceName = this.$route.params.deviceName || ''
				})
			})
			this.query.materialType = this.$route.params.materialType || ''

			this.getList()

			this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'
		},
		methods: {
			// NO.隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 1) {
					return 'blue'
				} else {
					return 'yellow'
				}
			},
			// 重置查询条件
			handleReset() {
				this.query = {
					searchKey: '',
					modelType: '',
					deviceType: '',
					deviceName: '',
					materialType: '',
					pageNo: 1,
					pageSize: 20,
                    libraryDeviceWithout: "library"
				};
				this.getList();
			},
			// 获取图书馆数据
			getList(page) {
				if (page) this.query.pageNo = page
				this.loading = true
				let that = this
				that.$request.get(
					"dataLibraryPageList",
					true,
					that.query,
					function (r) {
						if (r.code == "0") {
							that.data = r.data.list
							that.total = r.data.totalCount
						} else {
							that.$message.error(r.msg)
						}
						that.loading = false
					},
					function () {
						that.loading = false
					}
				)
			},
			// 获取机种分类
			getModelDevice() {
				let that = this
				that.$request.post(
					"sysModelDevice",
					true, {
                        libraryDeviceWithout: "library"
                    },
					function (r) {
						if (r.code == "0") {
							that.modelTypeOptions = r.data
						} else {
							that.$message.error(r.msg);
						}
					}
				)
			},
			// 获取资料类型
			getResourceType() {
				let that = this
				that.$request.post(
					"sysDicCodeList",
					true, {
						code: 'resourceType'
					},
					function (r) {
						if (r.code == "0") {
							that.resourceTypeOptions = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 新增
			add() {
				this.$router.push({
					name: 'libraryAddEdit',
					params: {
						pageNo: this.query.pageNo,
						searchKey: this.query.searchKey,
						modelType: this.query.modelType,
						deviceType: this.query.deviceType,
						deviceName: this.query.deviceName,
						materialType: this.query.materialType
					}
				})
			},
			// 编辑
			edit(row) {
				this.$router.push({
					name: 'libraryAddEdit',
					query: {
						id: row.id
					},
					params: {
						pageNo: this.query.pageNo,
						searchKey: this.query.searchKey,
						modelType: this.query.modelType,
						deviceType: this.query.deviceType,
						deviceName: this.query.deviceName,
						materialType: this.query.materialType
					}
				})
			},
			// 详情
			goToDetail(row) {
				this.$router.push({
					name: 'libraryDetail',
					query: {
						id: row.id
					},
					params: {
						pageNo: this.query.pageNo,
						searchKey: this.query.searchKey,
						modelType: this.query.modelType,
						deviceType: this.query.deviceType,
						deviceName: this.query.deviceName,
						materialType: this.query.materialType
					}
				})
			},
			// 下载文件
			download(row) {
				if (row.fileList.length === 0 && row.firmwareList.length === 0) return
				this.$alert(
					'本网站提供的技术资料，属于理光（中国）非公开的技术信息资料等，仅供理光认证工程师维修理光产品使用，非经理光（中国）许可，不得以任何形式、通过任何途径进行复制、发行或传播。',
					'',
					{
						confirmButtonText: '确定',
						callback: action => {
							if(row.materialType == '1428012982556266497') {
								if(row.firmwareList.length > 0) {
									this.gujianDownload(row.firmwareList[0].firmwareLink);
								}else {
									this.gujianDownload(row.fileList[0].fileAddr);
								}
							}else{
								if (row.fileList.length === 1) {
									this.downloadOne(row.fileList[0])
								} else {
									this.downloadZip(row)
								}
							}
						}
					}
				);

			},
			// 下载压缩包
			downloadZip(row) {
				const name = row.materialName + '.zip'
				if (!row.fileList || row.fileList.length === 0) return

				let that = this
				that.$request.download(
					"dataFileDownload",
					true,
					{
						linkedId: row.id,
						linkedTable: 'data_library'
					},
					function (res) {
						if (res) {
							let blob = new Blob([res], { type: 'application/zip' })
							let url = window.URL.createObjectURL(blob)
							const link = document.createElement('a') // 创建a标签
							link.href = url
							link.download = name // 重命名文件
							link.click()
							URL.revokeObjectURL(url) // 释放内存
						} else {
							that.$message.error(res.msg)
						}
					}
				)
			},
			// 下载单个文件
			downloadOne(file) {
				const url = file.fileAddr

				const link = document.createElement('a')
				link.style.display = 'none'
				link.href = url
				link.download = ''
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)

			},
			// 固件下载
			gujianDownload(url) {
				this.downloadLoading = true;
				let that = this;
				that.$request.get(
					"getDownloadKey",
					true, {},
					function (r) {
						setTimeout(() => {
							that.downloadLoading = false;
						}, 2000);
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = r.data.path + '?url='+url+'&key='+r.data.key;
						// linkNode.href = 'http://117.71.52.254:51196/file/rch/2022/01/20/mysql-8.0.25-linux-glibc2.12-x86_64.tar_112433.xz';
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);

					}
				)
			},
			// 删除
			del(row) {
				this.$confirm('是否删除当前资料?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(() => {
					let that = this
					that.$request.post(
						'dataLibraryDelete',
						true, {
							id: row.id
						},
						function (r) {
							if (r.code == "0") {
								that.getList()
								that.$message.success('删除成功')
							} else {
								that.$message.error(r.msg)
							}
						}
					)
				}).catch(() => {})
			},
			handleSizeChange() {
				this.getList()
			},
			handleCurrentChange() {
				this.getList()
			},
			// Safari 中 table 列同时启用 width 和 show-overflow-tip 时
			// el-tooltip 未正确设置 width 行内样式, Safari 不兼容 col 列设置的宽度
			// 内容过长时, 会导致撑开列宽
			getCellStyle({ column }) {
				const tempWidth = column.realWidth || column.width
				if (column.showOverflowTooltip) {
					return {
						minWidth: tempWidth + 'px',
						maxWidth: tempWidth + 'px',
					}
				}
				return {}
			}
		}
	}

</script>

<style lang="scss" scoped>
	.page {
		background-color: white;
	}

	@media screen and (max-width: 1608px) {
		.search-form {
			max-width: 666px;
		}
	}

	@media screen and (max-width: 1000px) {
		.search-form {
			max-width: 444px;
		}
	}

	@media screen and (max-width: 723px) {
		.search-form {
			max-width: 222px;
		}
	}
.search-btn{
	// float: unset !important;
	border: none;
	padding: 0 !important;
	margin: 0 !important;
	margin-left: 10px !important;
}
.refresh-btn{
	float: right;
	width: 32px;
	height: 32px;
	border: none;
	color: #117FFC;
	padding: 0;
	background-color: rgba(17, 127, 252, 0.2);
	font-weight: bold;
	font-size: 22px;
	vertical-align: 8px;
	margin-left: 5px;
	&:hover{
		background-color:  rgba(17, 127, 252, 0.3);
	}
}
</style>
